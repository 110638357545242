<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        :dirty="isDirty"
      >
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(slugify(item)) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <template v-if="item == 'form-tab-offers'">
              <component
                :is="item"
                v-model="obModel"
                @input="updateModel"
                @reload-offers="reloadOffers"
                :b-loading="bLocalLoading"
                :price-list="obPriceLists"
              />
            </template>
            <component
              :is="item"
              v-model="obModel"
              @input="updateModel"
              v-else
            />
          </v-tab-item>
        </v-tabs-items>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Component, Mixins, PropSync, Watch } from "vue-property-decorator";
import ProductsMixin from "@/modules/products/mixins/ProductsMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import FormTabSettings from "@/modules/products/components/tabs/Settings.vue";
import FormTabDescription from "@/modules/products/components/tabs/Description.vue";
// import FormTabOffers from "@/components/form/fields/Offers.vue";
import FormTabOffers from "@/modules/products/components/tabs/Offers.vue";
import { PriceListCollection } from "@planetadeleste/vue-mc-gw";
import { Product } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: {
    SheetDrawer,
    FormTabSettings,
    FormTabDescription,
    FormTabOffers,
  },
})
export default class ProductsForm extends Mixins(ProductsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = [
    "form-tab-settings",
    "form-tab-description",
    "form-tab-offers",
  ];
  obPriceLists: PriceListCollection = new PriceListCollection();

  @Watch("drawer")
  onOpen() {
    this.tab = 0;
  }

  async onMounted() {
    if (!this.obPriceLists.length) {
      await this.obPriceLists.fetch();
    }
  }

  updateModel(obModel: Record<string, any>) {
    this.$emit("input", obModel);
  }

  async reloadOffers() {
    this.bLocalLoading = true;
    const obModel = this.obModel.clone() as Product;
    await obModel.updateOffers();
    this.bLocalLoading = false;

    this.updateModel(obModel);
  }
}
</script>
